$psa-primary: #404550;
$psa-primary-accent: #303540;

$psa-contrast-light: #a2a2a8;

$psa-background-light: #e2e2e2;

$psa-border-light: #d2d2d2;

$psa-highlight: rgba(0, 155, 255, 0.05);
$bootstrap-button: #007bff;
$bootstrap-button-hover: #0069d9;
$bootstrap-button-active: #0062cc;
$bootstrap-disabled-button-color: white;
$bootstrap-disabled-button: #5aa9ff;
$bootstrap-disabled-button-alt-color: white;
$bootstrap-disabled-button-alt: #a0a5ab;

$bootstrap-button-red: #F04545;
$bootstrap-button-red-hover: #eb3535;
$bootstrap-button-red-active: #e02525;

$psa-error-message: #F04545;


$psa-blue-background: #5aa9ff;
$psa-red-background: #ff9595;
$psa-yellow-background: #fff0a0;
