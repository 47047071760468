@import "assets/css/variables";
/* You can add global styles to this file, and also import other style files */

// --------------------------------------------
//           MAIN COMPONENTS STYLE
// --------------------------------------------

// Body.
html, body {
  height: 100%;
  overflow: hidden;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-weight: 100;
  letter-spacing: 1px;
}

// Custom headers.
h1, h2, h3, h4, h5, h6 {
  font-weight: 500;
}

h1 {
  font-size: 33px;
  color: #898989; // #7f7f7f;
}

h2 {
  color: #7f7f7f; // #969696;
}

h3 {
  color: #6f6f6f; // #9b9b9b;
}

h4, h5, h6 {
  color: #0f0e0e;
}

p {
  color: #585858;
  font-weight: 500;
}

// Custom inputs.
input {
  height: 40px;
  min-width: 250px;
  background-color: #eeeeee;
  border: none;
  border-radius: 5px;
  padding: 0 15px;
  font-size: 14px;
  color: #585858;

  &:focus {
    outline: none;
  }

  // Placeholder.
  &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #9b9b9b;
    opacity: 1; /* Firefox */
    font-size: 14px;
    font-weight: 100;
  }

  &:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #9b9b9b;
    font-size: 14px;
    font-weight: 100;
  }

  &::-ms-input-placeholder { /* Microsoft Edge */
    color: #9b9b9b;
    font-size: 14px;
    font-weight: 100;
  }
}

// Custom buttons.
.mat-flat-button {
  font-weight: 300;
  letter-spacing: 1px;
  font-size: 15px;
  color: #585858;

  &.mat-primary, &.mat-accent {
    color: #585858;
  }

  &.mat-warn {
    color: white;
  }
}

.cdk-overlay-pane .mat-dialog-container {
  border-radius: 8px;
}
// --------------------------------------------
//                Generic Input
// --------------------------------------------
.app-input {
  background-color: rgba(255, 255, 255, 0);
  border-bottom: 1px solid $bootstrap-button;
  border-radius: 0;
  padding: 0;
  display: flex;
  align-items: flex-end;

  &:focus {
    border-width: 2px;
  }
}


// --------------------------------------------
//                Generic Button
// --------------------------------------------
.app-button {
  border: none;
  background-color: $bootstrap-button;
  color: white;
  padding: 4px 8px;
  font-weight: 700;
  height: 42px;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;

  &.error-button {
    background-color: $bootstrap-button-red;
  }

  &:focus {
    outline: none;
  }

  &:hover {
    background-color: $bootstrap-button-hover;


    &.error-button {
      background-color: $bootstrap-button-red-hover;
    }
  }

  &:active {
    background-color: $bootstrap-button-active;


    &.error-button {
      background-color: $bootstrap-button-red-active;
    }
  }

  &:disabled, &.disabled {
    background-color: $bootstrap-disabled-button-alt;
    color: $bootstrap-disabled-button-alt-color;
    cursor: default;
  }

  i {
    font-weight: 900;
  }

  span {
    font-family: Roboto, "Helvetica Neue", sans-serif;
  }
}

// --------------------------------------------
//                 SCROLL BAR
// --------------------------------------------
/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  border-right: solid 16px transparent;

}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(100, 117, 146, 0.3);
  border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(100, 117, 146, 0.5);
}

/* remove number input's spinners */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

/* windobe clear input*/
input[type=text]::-ms-clear {
  display: none;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }


.quotations-radio-block-custom {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  margin: auto 0;

  .bold {
    font-weight: 900;
  }
  .right-15px {
    margin-right: 15px;
  }
}

:not(output):-moz-ui-invalid {
    box-shadow: none;
}
